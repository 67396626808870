<template>
  <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
    <div class="pt-8 pb-4 shrink-0 flex items-center">
      <img src="/logo.svg" class="h-8" alt="Logo"/>
      <span class="font-headline font-bold text-primary ml-2 pb-px">CRYPTOMATOR HUB</span>
    </div>

    <div class="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
      <div class="absolute inset-0">
        <div class="absolute inset-0 bg-gradient-to-r from-primary-l1 to-primary mix-blend-multiply" />
      </div>
      <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
        <h1 class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl text-white">
          Unlock failed
        </h1>
        <p class="mt-6 max-w-lg mx-auto text-center text-xl text-primary-l2 sm:max-w-3xl">
          Your unlock failed unexpectedly. Please try again.
        </p>
      </div>
    </div>
  </div>
</template>
